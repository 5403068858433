.nav-link.active {
  font-weight: bold;
  color: #007bff; /* Example: blue color */
}
/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.whatsapp_float i {
  color: #fff;
}

.whatsapp-icon {
  margin-top: 16px;
}
.mob {
  display: none !important;
  background-repeat: no-repeat!important;
}
/* for mobile */
@media screen and (max-width: 767px) {
  .desk {
    display: none !important;
    background-repeat: no-repeat!important;
  }
  .mob {
    display: block !important;
    background-repeat: no-repeat!important;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

.call_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 40px;
  background-color: #4b25d3;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.call_float i {
  color: #fff;
}

.call-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .call-icon {
    margin-top: 10px;
  }

  .call_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    left: 10px;
    font-size: 22px;
  }
}
